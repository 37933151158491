<template>
  <v-container fluid>
    <label class="title">Colunas do Layout</label>
    <v-data-table
      :class="layout.columns && layout.columns.length > 0 ? 'elevation-1 mt-5' : 'elevation-0 mt-5'"
      :headers="headersColumnsTable"
      :items="sortLayoutByOrder"
      :show-select="false"
      :height="layout.columns && layout.columns.length > 5 ? 600 : null"
      :items-per-page="-1"
      :loading="loadingLayoutColumnsTable"
      item-key="order"
      hide-default-footer
      fixed-header
      hide-default-header
    >
      <template v-slot:no-data>
        <span>Nenhum item foi encontrado.</span>
      </template>
      <template v-slot:header="{ props }">
        <thead class="v-data-table-header">
          <tr class="table-header">
            <th style="background-color: #d9d9d9 !important;text-align: center;" v-for="header in props.headers" :key="header.text">
              <v-col v-if="header.text === 'Ações'" align="center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      small
                      v-bind="attrs"
                      v-on="on"
                      v-if="!propsIsVisualization"
                      @click="addColumn()"
                    >
                      <v-icon>
                        fas fa-plus
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Adicionar Coluna</span>
                </v-tooltip>
              </v-col>
              <label v-else class="label primary--text">
                {{ header.text }}
              </label>
            </th>
          </tr>
        </thead>
      </template>
      <template v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl" v-slot:body="props">
        <draggable :list="props.items" tag="tbody" handle=".handle">
          <tr v-for="(column, index) in props.items" :key="index">
            <template>
              <td>
                <v-icon :disabled="propsIsVisualization" small class="handle">
                  fas fa-grip-vertical
                </v-icon>
              </td>
            </template>
            <template>
              <td>
                <v-text-field
                  class="pt-4"
                  :value="column.order = index + 1"
                  solo
                  readonly
                  dense
                  :disabled="propsIsVisualization"
                />
              </td>
            </template>
            <template>
              <td>
              <v-text-field
                v-model="column.name"
                class="pt-4"
                dense
                solo
                required
                :rules="[rule.required]"
                :disabled="propsIsVisualization"
                :readonly="propsIsVisualization"
              />
              </td>
            </template>
            <template>
              <td>
                <v-autocomplete
                  v-model="column.type"
                  :items="types"
                  class="pt-4"
                  dense
                  solo
                  :disabled="propsIsVisualization"
                  :readonly="propsIsVisualization"
                />
              </td>
            </template>
            <template>
              <td>
                <v-text-field
                  label="Tamanho"
                  solo
                  dense
                  v-mask="'####'"
                  type="number"
                  class="pt-4"
                  v-model="column.size"
                  @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
                  @input="column.size ? column.size = formatter.removeInvalidNumber(column.size) : ''"
                  @blur="validateNumbersGreaterThanOne(column.size, 'fieldChecked')"
                  id="fieldChecked"
                  :rules="controlSizeInvalid ? ['Número deve ser maior que 0'] : [rule.required]"
                  :disabled="propsIsVisualization"
                  :readonly="propsIsVisualization"
                />
              </td>
            </template>
            <template>
              <td>
                <v-row class="pb-2">
                  <v-col align="end">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          color="#fff"
                          block
                          icon
                          v-bind="attrs"
                          v-on="on"
                          rounded
                          v-if="!propsIsVisualization"
                          @click="deleteColumn(column)"
                        >
                          <v-icon color="red">
                            fas fa-trash-alt
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Excluir Coluna</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </td>
            </template>
          </tr>
        </draggable>
      </template>
      <template v-else-if="$vuetify.breakpoint.xs" v-slot:body="props">
        <draggable :list="props.items" tag="tbody" handle=".handle">
          <tr class="border" v-for="(column, index) in props.items" :key="index">
            <template>
              <v-row class="mt-5">
                <v-col cols="12" align="center">
                  <v-icon small class="handle">
                    fas fa-grip-vertical
                  </v-icon>
                </v-col>
              </v-row>
            </template>
            <template>
              <v-text-field
                class="pt-4"
                :value="column.order =  index + 1"
                solo
                readonly
                dense
              />
            </template>
            <template>
              <v-text-field
                class="pt-4"
                dense
                v-model="column.name"
                solo
                required
                :rules="[rule.required]"
              />
            </template>
            <template>
              <v-autocomplete
                v-model="column.type"
                :items="types"
                class="pt-4"
                dense
                solo
              />
            </template>
            <template>
              <v-text-field
                label="Tamanho"
                solo
                dense
                v-mask="'####'"
                type="number"
                class="pt-4"
                v-model="column.size"
                @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
                @input="column.size ? column.size = formatter.removeInvalidNumber(column.size) : ''"
                @blur="validateNumbersGreaterThanOne(column.size, 'fieldChecked')"
                id="fieldChecked"
                :hide-details="false"
                :rules="controlSizeInvalid ? ['Número deve ser maior que 0'] : [rule.required]"
              />
            </template>
            <template>
              <v-row class="pb-2">
                <v-col align="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        color="#fff"
                        block
                        icon
                        v-bind="attrs"
                        v-on="on"
                        rounded
                        @click="deleteColumn(column)"
                      >
                        <v-icon color="red">
                          fas fa-trash-alt
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Excluir Coluna</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-divider/>
            </template>
          </tr>
        </draggable>
      </template>
    </v-data-table>

    <SnackbarCustomize ref="SnackbarCustomize" />
  </v-container>
</template>

<script>
import draggable from 'vuedraggable';
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import { observableMutationsRefreshToken } from '@/shared/observable/loadingRefreshToken';
import AuthService from '@/services-http/auth/AuthService';
import UserService from '@/services-http/security/UserService';
import UserUtils from '@/shared/utils/user-utils';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';

export default ({
  name: 'LayoutColumnComponent',

  components: { draggable, SnackbarCustomize },

  data: () => ({
    controlSizeInvalid: false,
    headersColumnsTable: [
      {
        align: 'center',
        width: '1%',
        sortable: false,
      },
      {
        text: 'Campo',
        value: 'order',
        align: 'center',
        width: '10%',
        sortable: false,
      },
      {
        text: 'Nome',
        value: 'name',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Tipo',
        value: 'type',
        align: 'center',
        width: '20%',
        sortable: false,
      },
      {
        text: 'Tamanho',
        value: 'size',
        align: 'center',
        width: '20%',
        sortable: false,
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'end',
        width: '8%',
        sortable: false,
      },
    ],
    types: [
      {
        value: 'ALPHANUMERIC',
        text: 'Alfanumérico',
      },
      {
        value: 'NUMERIC',
        text: 'Numérico',
      },
      {
        value: 'DATE',
        text: 'Data',
      },
    ],
    layout: {},
    columnPage: 1,
    loadingLayoutColumnsTable: true,
    SESSION_STORAGE_TOKEN: '@auth/token',
    SESSION_ODOO_TOKEN: '@auth-odoo/token',
  }),

  props: {
    propsLayout: Object,
    propsIsVisualization: Boolean,
  },

  watch: {
    propsLayout: {
      handler(val) {
        if (val) {
          this.layout = val;
          this.loadingLayoutColumnsTable = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    getTotalPage() {
      // eslint-disable-next-line radix
      return (this.layout.columns.length % 10) > 0 ? parseInt(this.layout.columns.length / 10) + 1 : this.layout.columns.length / 10;
    },
    sortLayoutByOrder() {
      if (this.layout && this.layout.columns && this.layout.columns.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.layout.columns.sort((a, b) => (a.order > b.order ? 1 : -1));
      }
      return [];
    },
  },

  methods: {
    async addColumn() {
      const tokenActive = sessionStorage.getItem(this.SESSION_STORAGE_TOKEN) || sessionStorage.getItem(this.SESSION_ODOO_TOKEN) || '';
      if (this.rule.checkExpiredToken(tokenActive)) await this.refreshToken();
      const layoutLength = this.layout.columns.length;
      this.layout.columns.splice(layoutLength, -1, {
        name: '',
        order: layoutLength,
        size: '',
        type: 'ALPHANUMERIC',
        transformations: [],
        validations: [],
      });

      this.reorderingLayout();
    },
    async refreshToken() {
      const TOKEN_PREFIX = 'Bearer ';
      observableMutationsRefreshToken.setLoadingRefreshToken(true);
      try {
        const response = await this.authService.GetTokenFullByRefresh();
        if (response && response.data) {
          const { token } = response.data;
          const fullToken = `${TOKEN_PREFIX}${token}`;
          sessionStorage.setItem(this.SESSION_STORAGE_TOKEN, fullToken);
          sessionStorage.setItem(this.SESSION_ODOO_TOKEN, fullToken);
          await this.loadUserSessionData();
        }
      } catch (error) {
        console.error('Error refreshing tokens:', error);
      } finally {
        observableMutationsRefreshToken.setLoadingRefreshToken(false);
      }
    },
    async loadUserSessionData() {
      this.userService = new UserService();
      await this.userService.GetCurrent().then((response) => {
        const session = response.data;
        if (session) {
          const userSessionStorage = {
            authenticated: session.authenticated,
            authorities: session.authorities,
            user: {
              name: session.principal && session.principal.user ? session.principal.user.name : null,
              email: session.name,
              financialGroupId: session.principal && session.principal.user ? session.principal.user.financialGroupExternalID : null,
              appCode: session.principal && session.principal.user ? session.principal.user.appCode : null,
            },
          };
          this.userUtils.createUserSessionStorage(userSessionStorage);
        }
      }).catch((error) => {
        if (error && error.response && error.response.status === 401) {
          this.$refs.SnackbarCustomize.open('error', 'Token inexistente');
        }
      });
    },
    deleteColumn(item) {
      const index = this.layout.columns.findIndex((element) => element.order === item.order);
      this.layout.columns.splice(index, 1);

      this.reorderingLayout();
    },
    validateNumbersGreaterThanOne(value, id) {
      if (parseFloat(value) < 1) {
        switch (id) {
          case 'fieldChecked':
            this.controlSizeInvalid = true;
            this.$emit('controlSizeInvalid', this.controlSizeInvalid);
            break;
          default:
            this.controlSizeInvalid = true;
            this.$emit('controlSizeInvalid', this.controlSizeInvalid);
            break;
        }
      } else {
        this.controlSizeInvalid = false;
        this.$emit('controlSizeInvalid', this.controlSizeInvalid);
      }
    },
    reorderingLayout() {
      this.layout.columns = this.layout.columns.map((element, index) => ({
        ...element,
        order: index + 1,
      }));
    },
  },

  created() {
    this.rule = new Rules();
    this.formatter = new Formatters();
    this.authService = new AuthService();
    this.userUtils = new UserUtils();
  },
});
</script>
